import React, { useState, useEffect, useRef } from "react";
import { db, auth } from "../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut, updateProfile } from "firebase/auth";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { FaLinkedin, FaTrash, FaUserPlus, FaFilter, FaSort, FaPhoneAlt, FaEnvelope, FaCheck } from "react-icons/fa";
import './ContactList.css';
import vCard from 'vcard-parser';
import MessageBoard from './MessageBoard'; // Import the MessageBoard component

// Function to check if a contact already exists in Firestore
const checkIfContactExists = async (name, email) => {
  const contactsQuery = query(
    collection(db, "contacts"),
    where("name", "==", name),
    where("email", "==", email)
  );

  const querySnapshot = await getDocs(contactsQuery);
  return !querySnapshot.empty;
};

// Function to clean phone numbers
const cleanPhoneNumber = (phone) => {
  if (!phone) return "";
  let cleanedPhone = phone.replace(/[\s-]/g, ''); // Remove spaces and dashes
  if (cleanedPhone.startsWith("08")) {
    cleanedPhone = "+628" + cleanedPhone.slice(2); // Convert local format to international format for Indonesia
  } else if (!cleanedPhone.startsWith("+")) {
    cleanedPhone = "+" + cleanedPhone; // Ensure it starts with a "+"
  }
  return cleanedPhone;
};

// Function to format date to "DD-MMM-YYYY"
const formatDate = (timestamp) => {
  if (!timestamp) return "";
  const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  return date.toLocaleDateString("en-GB", { day: '2-digit', month: 'short', year: 'numeric' });
};

// Function to format date and time to "DD/MM/YYYY, HH:MM"
const formatDateTime = (timestamp) => {
  if (!timestamp) return "";
  const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  return date.toLocaleString("en-GB", { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' });
};

const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const [user] = useAuthState(auth);
  const [editingField, setEditingField] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [newContact, setNewContact] = useState({
    name: "",
    jobTitle: "",
    company: "",
    phone: "",
    email: "",
    linkedIn: "",
    todo: "invite; attend",
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [editingUserName, setEditingUserName] = useState(false);
  const [newUserName, setNewUserName] = useState(user?.displayName || '');
  const [showMessageModal, setShowMessageModal] = useState(false); // State to show/hide message modal
  const deleteButtonRef = useRef(null);

  useEffect(() => {
    if (user) {
      const unsubscribe = onSnapshot(
        collection(db, "contacts"),
        (snapshot) => {
          const sortedContacts = snapshot.docs.map((doc) => {
            const data = doc.data();
            return { ...data, id: doc.id };
          });
          setContacts(sortedContacts);
        }
      );
      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (deleteButtonRef.current && !deleteButtonRef.current.contains(event.target)) {
        setDeleteConfirm(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);

    const vCardFiles = files.filter((file) => file.name.toLowerCase().endsWith(".vcf"));

    if (vCardFiles.length === 0) {
      alert("Please upload valid .vcf files.");
      return;
    }

    for (const file of vCardFiles) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const vCardData = event.target.result;
        const parsedData = vCard.parse(vCardData);
        const parsedContacts = Array.isArray(parsedData) ? parsedData : [parsedData];

        for (const contact of parsedContacts) {
          const name = contact.fn && Array.isArray(contact.fn) ? contact.fn[0].value : (contact.fn?.value || "");
          const email = contact.email && Array.isArray(contact.email) ? contact.email[0].value : (contact.email?.value || "");

          if (await checkIfContactExists(name, email)) {
            console.log("Contact already exists in Firestore:", name);
            continue;
          }

          const jobTitle = contact.title && Array.isArray(contact.title) ? contact.title[0].value : (contact.title?.value || "");
          const company = contact.org && Array.isArray(contact.org) ? contact.org[0].value : (contact.org?.value || "");
          const phone = contact.tel && Array.isArray(contact.tel) ? cleanPhoneNumber(contact.tel[0].value) : cleanPhoneNumber(contact.tel?.value || "");

          let linkedIn = "";
          Object.keys(contact).forEach((key) => {
            if (key.toLowerCase().includes("x-socialprofile")) {
              contact[key].forEach((profile) => {
                if (profile.meta && profile.meta.type && Array.isArray(profile.meta.type) && profile.meta.type[0].toLowerCase() === "linkedin") {
                  linkedIn = profile.value;
                }
              });
            }
          });

          const newContact = {
            name,
            jobTitle,
            company,
            phone,
            email,
            linkedIn,
            todo: "invite; attend",
            createdBy: user.displayName,
            createdDate: new Date(),
            updatedBy: user.displayName,
            lastUpdated: new Date(),
          };

          try {
            await addDoc(collection(db, "contacts"), newContact);
            console.log("Contact added successfully.");
          } catch (error) {
            console.error("Error adding contact to Firestore:", error);
          }
        }

        alert("Contacts imported successfully!");
      };
      reader.readAsText(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleShowFilterModal = () => {
    if (showFilterModal) {
      setFilterText('');
    }
    setShowFilterModal(!showFilterModal);
  };

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const addContact = async () => {
    if (newContact.name) {
      await addDoc(collection(db, "contacts"), {
        ...newContact,
        createdBy: user.displayName,
        createdDate: new Date(),
        updatedBy: user.displayName,
        lastUpdated: new Date(),
      });
      setShowModal(false);
      setNewContact({
        name: "",
        jobTitle: "",
        company: "",
        phone: "",
        email: "",
        linkedIn: "",
        todo: "invite; attend",
      });
    }
  };

  const updateContact = async (id, field, value) => {
    const contactDoc = doc(db, "contacts", id);
    await updateDoc(contactDoc, { [field]: value, updatedBy: user.displayName, lastUpdated: new Date() });
    setEditingField(null);
  };

  const handleFieldChange = (contact, field, value) => {
    const updatedContacts = contacts.map((c) => (c.id === contact.id ? { ...c, [field]: value } : c));
    setContacts(updatedContacts);
  };

  const handleKeyDown = (e, contact) => {
    if (e.key === 'Enter') {
      updateContact(contact.id, editingField.field, e.target.value);
    } else if (e.key === 'Escape') {
      setEditingField(null);
    }
  };

  const handleEditClick = (e, id, field) => {
    if (e.type === 'contextmenu' || (e.type === 'click' && (e.ctrlKey || e.metaKey))) {
      e.preventDefault();
      setEditingField({ id, field });
    }
  };

  const handleDeleteClick = (id) => {
    if (deleteConfirm === id) {
      deleteContact(id);
    } else {
      setDeleteConfirm(id);
    }
  };

  const deleteContact = async (id) => {
    try {
      await deleteDoc(doc(db, "contacts", id));
      setContacts((prevContacts) => prevContacts.filter(contact => contact.id !== id));
      setDeleteConfirm(null);
    } catch (error) {
      console.error("Error deleting contact: ", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (deleteButtonRef.current && !deleteButtonRef.current.contains(event.target)) {
        setDeleteConfirm(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deleteButtonRef]);

  const handleChangeUserName = async () => {
    await updateProfile(user, { displayName: newUserName });
    const contactsQuery = query(collection(db, "contacts"), where("createdBy", "==", user.displayName));
    const querySnapshot = await getDocs(contactsQuery);
    querySnapshot.forEach(async (contactDoc) => {
      await updateDoc(contactDoc.ref, { createdBy: newUserName, updatedBy: newUserName });
    });
    setEditingUserName(false);
  };

  const handleLogout = async () => {
    await signOut(auth);
  };

  const sortContacts = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedContacts = [...contacts].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setContacts(sortedContacts);
    setSortConfig({ key, direction });
  };

  const renderEditableField = (contact, field) => {
    const fieldValue = contact[field];

    return (
      <input
        value={fieldValue || ''}
        onChange={(e) => handleFieldChange(contact, field, e.target.value)}
        onBlur={(e) => {
          updateContact(contact.id, field, e.target.value);
          setEditingField(null);
        }}
        onKeyDown={(e) => handleKeyDown(e, contact)}
        autoFocus
        className="inline-edit-input"
      />
    );
  };

  const renderTodoTags = (contact) => {
    const todoField = 'todo';
    return (
      <div
        className="contact-todo"
        onContextMenu={(e) => {
          e.preventDefault();
          handleEditClick(e, contact.id, todoField);
        }}
        onClick={(e) => handleEditClick(e, contact.id, todoField)}
      >
        {editingField?.id === contact.id && editingField.field === todoField ? (
          renderEditableField(contact, todoField)
        ) : (
          contact.todo.split(';').map((todo, index) => (
            <span
              key={index}
              className={`todo-tag ${todo.trim().includes('[done]') ? 'todo-completed' : ''}`}
              onClick={() => handleTodoClick(contact.id, index)}
            >
              {todo.trim().includes('[done]') && (
                <>
                  <FaCheck style={{ marginRight: '5px' }} />
                </>
              )}
              {todo.trim().replace('[done]', '')}
            </span>
          ))
        )}
      </div>
    );
  };

  const handleTodoClick = (contactId, todoIndex) => {
    const updatedContacts = contacts.map((contact) => {
      if (contact.id === contactId) {
        const todos = contact.todo.split(';');
        if (todos[todoIndex].includes('[done]')) {
          todos[todoIndex] = todos[todoIndex].replace('[done]', '').trim();
        } else {
          todos[todoIndex] = `${todos[todoIndex].trim()} [done]`;
        }
        return { ...contact, todo: todos.join('; ') };
      }
      return contact;
    });
    setContacts(updatedContacts);
    updateContact(contactId, 'todo', updatedContacts.find(contact => contact.id === contactId).todo);
  };

  const completedTodosDetails = contacts.reduce((acc, contact) => {
    const todos = contact.todo.split(';');
    todos.forEach(todo => {
      if (todo.includes('[done]')) {
        const cleanTodo = todo.replace('[done]', '').trim();
        acc[cleanTodo] = (acc[cleanTodo] || 0) + 1;
      }
    });
    return acc;
  }, {});

  const totalContacts = contacts.length;

  const filteredContacts = contacts.filter(contact => {
    const searchText = filterText.toLowerCase();

    const name = typeof contact.name === 'string' ? contact.name.toLowerCase() : '';
    const jobTitle = typeof contact.jobTitle === 'string' ? contact.jobTitle.toLowerCase() : '';
    const company = typeof contact.company === 'string' ? contact.company.toLowerCase() : '';
    const phone = typeof contact.phone === 'string' ? contact.phone.toLowerCase() : '';
    const email = typeof contact.email === 'string' ? contact.email.toLowerCase() : '';
    const linkedIn = typeof contact.linkedIn === 'string' ? contact.linkedIn.toLowerCase() : '';
    const todo = typeof contact.todo === 'string' ? contact.todo.toLowerCase() : '';

    return (
      name.includes(searchText) ||
      jobTitle.includes(searchText) ||
      company.includes(searchText) ||
      phone.includes(searchText) ||
      email.includes(searchText) ||
      linkedIn.includes(searchText) ||
      todo.includes(searchText)
    );
  });

  const handleMessageClick = () => {
    console.log('Opening message modal...'); // Debugging: Log when modal should open
    setShowMessageModal(true); // Show the message modal when the message board is clicked
  };

  return (
    <div className="contact-list-container">
      {user && (
        <div className="contact-list-wrapper">
          <div className="header">
            <h2>Contacts</h2>
            <div className="profile">
              <span
                className="user-name"
                onClick={() => setEditingUserName(true)}
              >
                {editingUserName ? (
                  <input
                    type="text"
                    value={newUserName}
                    onChange={(e) => setNewUserName(e.target.value)}
                    onBlur={handleChangeUserName}
                    className="username-input"
                    autoFocus
                  />
                ) : user.displayName}
              </span>
              <img
                src={user.photoURL}
                alt="Profile"
                className="profile-pic"
                onClick={handleLogout}
              />
            </div>
          </div>
          <div className="header-actions">
            <div
              className="file-drop-area"
              onDrop={handleFileDrop}
              onDragOver={handleDragOver}
            >
              Drop .vcf file here to import contacts
            </div>

            <button
              className={`filter-btn ${showFilterModal ? 'active' : ''}`}
              onClick={handleShowFilterModal}
              style={{ backgroundColor: showFilterModal ? '#D4C2FC' : 'white' }} 
            >
              <FaFilter className="icon" /> Filter
            </button>
            <button
              className={`add-contact-btn ${showModal ? 'active' : ''}`}
              onClick={handleShowModal}
            >
              <FaUserPlus className="icon" /> Add New Contact
            </button>
          </div>

          {showFilterModal && (
            <div className="filter-modal" style={{ backgroundColor: '#D4C2FC' }}>
              <input
                type="text"
                value={filterText}
                onChange={handleFilterChange}
                className="filter-input"
                placeholder="Filter contacts..."
              />
            </div>
          )}

          {showModal && (
            <div
              className="modal animated-modal"
              style={{ top: '124px', right: '0px', position: 'absolute', backgroundColor: '#D4C2FC', paddingRight: '20px' }}
            >
              <div className="modal-content">
                <input
                  type="text"
                  placeholder="Name"
                  value={newContact.name}
                  onChange={(e) => setNewContact({ ...newContact, name: e.target.value })}
                  className="modal-input"
                />
                <input
                  type="text"
                  placeholder="Job Title"
                  value={newContact.jobTitle}
                  onChange={(e) => setNewContact({ ...newContact, jobTitle: e.target.value })}
                  className="modal-input"
                />
                <input
                  type="text"
                  placeholder="Company"
                  value={newContact.company}
                  onChange={(e) => setNewContact({ ...newContact, company: e.target.value })}
                  className="modal-input"
                />
                <input
                  type="text"
                  placeholder="Phone"
                  value={newContact.phone}
                  onChange={(e) => setNewContact({ ...newContact, phone: e.target.value })}
                  className="modal-input"
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={newContact.email}
                  onChange={(e) => setNewContact({ ...newContact, email: e.target.value })}
                  className="modal-input"
                />
                <input
                  type="text"
                  placeholder="LinkedIn URL"
                  value={newContact.linkedIn}
                  onChange={(e) => setNewContact({ ...newContact, linkedIn: e.target.value })}
                  className="modal-input"
                />
                <input
                  type="text"
                  value={newContact.todo}
                  onChange={(e) => setNewContact({ ...newContact, todo: e.target.value })}
                  className="modal-input"
                  title="Add more todo for this contact"
                />
                <button className="save-btn" onClick={addContact}>Save Contact</button>
                <button className="cancel-btn" onClick={() => setShowModal(false)}>Cancel</button>
              </div>
            </div>
          )}

          <div className="contact-table">
            <div className="contact-table-header">
              <div className="contact-column name-column">Name <FaSort className="sort-icon" onClick={() => sortContacts('name')} /></div>
              <div className="contact-column job-title-column">Job Title <FaSort className="sort-icon" onClick={() => sortContacts('jobTitle')} /></div>
              <div className="contact-column company-column">Company <FaSort className="sort-icon" onClick={() => sortContacts('company')} /></div>
              <div className="contact-column phone-column"><FaPhoneAlt /></div>
              <div className="contact-column email-column"><FaEnvelope /></div>
              <div className="contact-column linkedin-column"><FaLinkedin /></div>
              <div className="contact-column">Todo</div>
              <div className="contact-column">Created</div>
              <div className="contact-column">Updated</div>
              <div className="contact-column">Actions</div>
            </div>

            {filteredContacts.map((contact) => (
              <div key={contact.id} className="contact-table-row">
                <div
                  className="contact-name"
                  onContextMenu={(e) => handleEditClick(e, contact.id, 'name')}
                  onClick={(e) => handleEditClick(e, contact.id, 'name')}
                >
                  {editingField?.id === contact.id && editingField.field === 'name'
                    ? renderEditableField(contact, 'name')
                    : contact.name}
                </div>
                <div
                  className="contact-jobTitle"
                  onContextMenu={(e) => handleEditClick(e, contact.id, 'jobTitle')}
                  onClick={(e) => handleEditClick(e, contact.id, 'jobTitle')}
                >
                  {editingField?.id === contact.id && editingField.field === 'jobTitle'
                    ? renderEditableField(contact, 'jobTitle')
                    : contact.jobTitle}
                </div>
                <div
                  className="contact-company"
                  onContextMenu={(e) => handleEditClick(e, contact.id, 'company')}
                  onClick={(e) => handleEditClick(e, contact.id, 'company')}
                >
                  {editingField?.id === contact.id && editingField.field === 'company'
                    ? renderEditableField(contact, 'company')
                    : contact.company}
                </div>
                <div
                  className="contact-phone"
                  onContextMenu={(e) => handleEditClick(e, contact.id, 'phone')}
                  onClick={(e) => handleEditClick(e, contact.id, 'phone')}
                >
                  {editingField?.id === contact.id && editingField.field === 'phone'
                    ? renderEditableField(contact, 'phone')
                    : (
                      contact.phone ? (
                        <a href={`https://wa.me/${contact.phone}`} target="_blank" rel="noopener noreferrer" title={contact.phone}>
                          <FaPhoneAlt />
                        </a>
                      ) : (
                        <FaPhoneAlt style={{ color: '#ccc' }} />
                      )
                    )}
                </div>
                <div
                  className="contact-email"
                  onContextMenu={(e) => handleEditClick(e, contact.id, 'email')}
                  onClick={(e) => handleEditClick(e, contact.id, 'email')}
                >
                  {editingField?.id === contact.id && editingField.field === 'email'
                    ? renderEditableField(contact, 'email')
                    : (
                      contact.email ? (
                        <a href={`mailto:${contact.email}`} target="_blank" rel="noopener noreferrer" title={contact.email}>
                          <FaEnvelope />
                        </a>
                      ) : (
                        <FaEnvelope style={{ color: '#ccc' }} />
                      )
                    )}
                </div>
                <div
                  className="contact-linkedin"
                  onContextMenu={(e) => handleEditClick(e, contact.id, 'linkedIn')}
                  onClick={(e) => handleEditClick(e, contact.id, 'linkedIn')}
                >
                  {editingField?.id === contact.id && editingField.field === 'linkedIn'
                    ? renderEditableField(contact, 'linkedIn')
                    : (
                      contact.linkedIn ? (
                        <a href={contact.linkedIn} target="_blank" rel="noopener noreferrer" title={contact.linkedIn}>
                          <FaLinkedin />
                        </a>
                      ) : (
                        <FaLinkedin style={{ color: '#ccc' }} />
                      )
                    )}
                </div>
                <div className="contact-todo">
                  {renderTodoTags(contact)}
                </div>
                <div className="created-info" title={`${contact.createdBy} - ${formatDateTime(contact.createdDate)}`}>
                  <img src={user.photoURL} alt="User" className="user-icon" />
                  <div>
                    <span>{formatDate(contact.createdDate)}</span>
                  </div>
                </div>
                <div className="updated-info" title={`${contact.updatedBy} - ${formatDateTime(contact.lastUpdated)}`}>
                  <img src={user.photoURL} alt="User" className="user-icon" />
                  <div>
                    <span>{formatDate(contact.lastUpdated)}</span>
                  </div>
                </div>
                <div className="contact-actions">
                  <button
                    ref={deleteConfirm === contact.id ? deleteButtonRef : null}
                    className={`delete-btn ${deleteConfirm === contact.id ? 'delete-confirm' : ''}`}
                    onClick={() => handleDeleteClick(contact.id)}>
                    {deleteConfirm === contact.id ? "Delete?" : <FaTrash />}
                  </button>
                </div>
              </div>
            ))}
          </div>
          
          <div className="stats-and-messages-container">
            {/* Contact Statistics */}
            <div className="contact-stats">
              <div>Total Contacts: {totalContacts}</div>
              {Object.entries(completedTodosDetails).map(([todo, count]) => (
                <div key={todo} className="contact-stats-item">{todo}: {count}</div>
              ))}
              </div>
              {/* Message Board */}
              <div className="message-board" onClick={handleMessageClick} style={{ marginLeft: '20px', maxHeight: '150px', overflowY: 'auto', cursor: 'pointer', backgroundColor: '#f9f9f9', padding: '10px', border: '1px solid #ddd' }}>
                <h4>Messages</h4>
                <p>Tips: right clicking the item in the list enables you to edit the content</p>
              </div>
          </div>

          {/* Modal for Messages - Importing the actual Message Board */}
          <MessageBoard showMessageModal={showMessageModal} setShowMessageModal={setShowMessageModal} />
        </div>
      )}
    </div>
  );
};

export default ContactList;
