// src/App.js
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebaseConfig";
import Login from "./components/Login";
import ContactList from "./components/ContactList";

function App() {
  const [user] = useAuthState(auth);

  return <div className="App">{user ? <ContactList /> : <Login />}</div>;
}

export default App;
