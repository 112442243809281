import React, { useState, useEffect, useRef, useCallback } from 'react';
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebaseConfig";

const MessageBoard = ({ showMessageModal, setShowMessageModal }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [editableContent, setEditableContent] = useState(''); // State for editable content
  const [isEditing, setIsEditing] = useState(false); // State to control editing mode
  const [showArrow, setShowArrow] = useState(false); // State to control arrow visibility
  const messageBoardRef = useRef(null); // Reference to the message board content container
  const scrollIntervalRef = useRef(null); // Reference to the scroll interval

  // Fetch messages from Firestore and set initial editable content
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "messages"), // Make sure this is the correct collection name
      (snapshot) => {
        const fetchedMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log('Fetched Messages:', fetchedMessages); // Debugging: Check fetched messages

        if (fetchedMessages.length > 0) {
          setMessages(fetchedMessages);
          setEditableContent(fetchedMessages.map(msg => msg.content).join("\n")); // Combine messages for editing
        } else {
          // If no messages, set default content to ensure editability
          console.log("No messages found in the 'messages' collection.");
          setEditableContent('Click here to edit the message board.');
        }
      },
      (error) => {
        console.error("Error fetching messages from Firestore:", error); // Debugging: Log Firestore error
      }
    );
    return () => unsubscribe();
  }, []);

  // Function to start scrolling
  const startScrolling = useCallback(() => {
    const scrollContainer = messageBoardRef.current;
    if (!scrollContainer) {
      console.error("Message board reference is not set.");
      return;
    }

    console.log("Scroll container height:", scrollContainer.clientHeight);
    console.log("Scroll container content height:", scrollContainer.scrollHeight);

    checkIfContentOverflowing(); // Check if content is overflowing initially

    // Clear any existing interval to prevent duplicate intervals
    stopScrolling();

    // Scrolling function
    let scrollStep = 1; // Pixels to scroll
    scrollIntervalRef.current = setInterval(() => {
      if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) {
        scrollContainer.scrollTop = 0; // Reset scroll to top when reaching the end
      } else {
        scrollContainer.scrollTop += scrollStep; // Scroll down by 1 pixel
      }
      checkIfContentOverflowing(); // Check if content is overflowing during scrolling
    }, 50); // Adjust scroll speed (in milliseconds)

    console.log("Scrolling started");
  }, []);

  // Function to stop scrolling
  const stopScrolling = useCallback(() => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
      console.log("Scrolling stopped");
    }
  }, []);

  // Function to check if content is overflowing
  const checkIfContentOverflowing = useCallback(() => {
    const scrollContainer = messageBoardRef.current;
    if (scrollContainer) {
      const isOverflowing = scrollContainer.scrollTop + scrollContainer.clientHeight < scrollContainer.scrollHeight;
      console.log("Checking overflow:", isOverflowing);
      console.log("scrollTop:", scrollContainer.scrollTop);
      console.log("clientHeight:", scrollContainer.clientHeight);
      console.log("scrollHeight:", scrollContainer.scrollHeight);
      setShowArrow(isOverflowing); // Update state to show or hide arrow
    } else {
      console.log("Scroll container is not defined.");
    }
  }, []);

  // Ensure scrolling setup when component is fully rendered
  useEffect(() => {
    if (showMessageModal && messageBoardRef.current) { // Ensure modal is visible and ref is set
      console.log("Message board is ready. Starting initialization.");
      checkIfContentOverflowing();
      startScrolling();
    } else if (showMessageModal) {
      console.log("Waiting for message board ref to be set...");
    }

    // Cleanup function to stop scrolling on component unmount
    return () => stopScrolling();
  }, [showMessageModal, messages, startScrolling, stopScrolling, checkIfContentOverflowing]); // Ensure `showMessageModal` is a dependency

  // Define the addMessage function
  const addMessage = async () => {
    if (newMessage.trim()) {
      await addDoc(collection(db, "messages"), {
        content: newMessage,
        createdDate: new Date(),
      });
      setNewMessage(""); // Clear input after adding message
      checkIfContentOverflowing(); // Re-check for overflow when a new message is added
    }
  };

  // Handle editable content change
  const handleContentChange = (event) => {
    setEditableContent(event.target.innerText); // Update state with edited content
  };

  // Toggle editing mode on click
  const toggleEditing = () => {
    setIsEditing(true);
    stopScrolling(); // Stop scrolling when editing
  };

  return (
    showMessageModal && (  // Ensure this conditional rendering is working correctly
      <div className="modal">
        <div className="modal-content">
          <h2>Message Board</h2>
          <div
            className="message-board"
            ref={messageBoardRef}
            contentEditable={isEditing} // Toggle editable state
            suppressContentEditableWarning={true} // Suppress content editable warning
            onInput={handleContentChange} // Update state when content changes
            onClick={toggleEditing} // Toggle editing mode on click
            onMouseOver={stopScrolling} // Stop scrolling on hover
            onMouseOut={!isEditing ? startScrolling : undefined} // Resume scrolling when not hovering and not editing
            style={{ height: '70px', overflow: 'auto', position: 'relative', border: '1px solid #ddd', padding: '5px', cursor: 'text' }} // Added cursor style
          >
            {editableContent}
            {/* Arrow indicator for more messages below */}
            {showArrow && (
              <div className="scroll-arrow" style={{ position: 'absolute', bottom: '5px', left: '50%', transform: 'translateX(-50%)' }}>
                ▼
              </div>
            )}
          </div>
          <textarea
            placeholder="Type a new message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            rows={2}
            style={{ width: '100%' }}
          />
          <button onClick={addMessage} style={{ marginTop: '10px' }}>Add Message</button>
          <button onClick={() => setShowMessageModal(false)} style={{ marginTop: '10px' }}>Close</button>
        </div>
      </div>
    )
  );
};

export default MessageBoard;
