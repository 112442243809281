// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAqjLUkOB6tRvbqO7hsuNQv_VYBvCuhmXg",
    authDomain: "contact-list-e7s.firebaseapp.com",
    projectId: "contact-list-e7s",
    storageBucket: "contact-list-e7s.appspot.com",
    messagingSenderId: "592233254856",
    appId: "1:592233254856:web:1099712b4e7e707a153afd"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
